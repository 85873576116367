<template>
  <div class="link-wrapper">
    <div class="link">
      <span>{{ link }}</span>
      <a-tooltip title="复制链接">
        <i class="icon ndl-icon-copy" @click="copy()"></i>
      </a-tooltip>
      <a-tooltip title="在新窗口打开链接">
        <i class="icon ndl-icon-share-box-line" @click="open()"></i>
      </a-tooltip>
      <a-popover>
        <img slot="content" :src="qrcode" style="width: 120px" />
        <svg
          class="icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="1.1em"
          height="1.1em"
          fill="currentColor"
        >
          <path
            d="M16 17v-1h-3v-3h3v2h2v2h-1v2h-2v2h-2v-3h2v-1h1zm5 4h-4v-2h2v-2h2v4zM3 3h8v8H3V3zm2 2v4h4V5H5zm8-2h8v8h-8V3zm2 2v4h4V5h-4zM3 13h8v8H3v-8zm2 2v4h4v-4H5zm13-2h3v2h-3v-2zM6 6h2v2H6V6zm0 10h2v2H6v-2zM16 6h2v2h-2V6z"
          />
        </svg>
      </a-popover>
    </div>
    <a-button type="danger" @click="$emit('cancel')">取消分享</a-button>
  </div>
</template>

<script>
import QRious from "qrious";
import cliptext from "copy-to-clipboard";

export default {
  props: {
    link: String
  },
  data() {
    return {
      qrcode: null
    };
  },
  created() {
    this.generateQRCode();
  },
  watch: {
    link() {
      this.generateQRCode();
    }
  },
  methods: {
    generateQRCode() {
      const qr = new QRious({
        value: this.link,
        foreground: "#406fff",
        // foregroundAlpha: 1,
        size: 500
      });
      this.qrcode = qr.toDataURL();
    },
    copy() {
      cliptext(this.link);
      this.$message.success("已复制");
    },
    open() {
      window.open(this.link);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "~@/assets/app.less";

.link-wrapper {
  display: flex;
  align-items: center;
  .link {
    flex: 1 1 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 36px;
    text-align: left;
    background-color: rgb(245, 247, 250);
    color: rgb(38, 38, 38);
    border-radius: 4px;
    padding: 0 12px;
    span {
      flex: 1 1 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      user-select: all;
    }
    .icon {
      cursor: pointer;
      margin-left: 14px;
    }
    .icon:hover {
      opacity: 0.65;
    }
  }
  .ant-btn {
    margin-left: 12px;
  }
}
</style>
